$bp-sm: "screen and (min-width: 576px)";
$bp-md: "screen and (min-width: 768px)";
$bp-lg: "screen and (min-width: 992px)";
$bp-xl: "screen and (min-width: 1200px)";

.coach-wrapper {
   display: grid;
   gap: 0.5rem;
   grid-template-columns: 1fr 1fr;
   grid-auto-flow: dense;

   @media #{$bp-sm} {
      grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
   }

   @media #{$bp-md} {
      gap: 1.5vw;
   }

   @media #{$bp-xl} {
      gap: 1rem;
      grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
   }
}

.coach-card {
   background-color: white;
   border-radius: 0.75rem;
   display: flex;
   flex-direction: column;
   gap: 0.5rem;
   min-height: 156px;
   padding: 1rem; // 1rem 3rem;
}

.coach-content {
   display: flex;
   flex-direction: column;
   flex-grow: 1;
   font-size: 0.75rem;
   gap: 0.5rem;

   .coach-score-percentage {
      font-weight: 700;
      font-size: 1.1rem;
      letter-spacing: -0.05rem;

      @media #{$bp-sm} {
         font-size: 1.5rem;
      }

      @media #{$bp-lg} {
         font-size: calc(1.3rem + 0.6vw);
      }
   }
}
.coach-content.rpad {
   @media #{$bp-sm} {
      padding-right: 1rem;
   }

   @media #{$bp-md} {
      padding-right: 2rem;
   }
}

.coach-filler-word {
   background-color: lightgrey;
   border-radius: 0.5rem;
   font-weight: 600;
   padding: 0.5rem;
   text-transform: capitalize;
}

.coach-title {
   display: flex;
   align-items: center;
   gap: 0.35rem;
   font-size: 0.875rem;
   font-weight: 700;
}
