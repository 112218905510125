$bp-sm: "screen and (min-width: 576px)";
$bp-md: "screen and (min-width: 768px)";
$bp-lg: "screen and (min-width: 992px)";
$bp-xl: "screen and (min-width: 1200px)";

.interview-head-text {
   // color: #5CD883;
   font-size: calc(14px + 1vw);
   font-weight: 800;
   white-space: nowrap;

   @media #{$bp-sm} {
      font-size: 22px;
   }
}
.interview-row {
   display: flex;
   flex-direction: column;
   gap: 1rem;
   padding-left: 0.75rem;
   padding-right: 0.75rem;
   
   @media #{$bp-md} {
      flex-direction: row;
      gap: 3vw;
   }

   @media #{$bp-xl} {
      gap: 2rem
   }
}

.interview-stats {
   background-color: #F1F1F2;
   border-radius: 1.5rem;
   padding: 1rem;

   .icon-button {
      align-items: center;
      background-color: lightgray;
      border-radius: 7px;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      gap: 0.33rem;
      justify-content: center;
      padding: 0.75rem;
      text-align: center;

      .icon-button-subtext {
         font-size: 12px;
      }
   }
}

.interview-vert-gap {
   gap: 1rem;
   
   @media #{$bp-md} {
      gap: 3vw;
   }
   @media #{$bp-xl} {
      gap: 2rem;
   }
}
.interview-video {
   aspect-ratio: 16 / 9;
   border: 1px solid lightgrey;
   border-radius: 1.5rem;
   object-fit: cover;
   width: 100%;
}

.interview-wrapper {
   display: flex;
   flex-direction: column;
   gap: 2rem;
   padding: 0.5rem;

   @media #{$bp-sm} {
      padding: 1rem;
   }
}
.overlay-text {
   background-color: black;
   color: white;
   opacity: 66%;

   padding-left: 0.25rem;
   padding-right: 0.25rem;
   border-radius: 0.25rem;
}
.name-display {
   position: absolute;
   left: 0.5rem;
   bottom: 0.75rem;
   z-index: 2;

   background-color: black;
   color: white;
   opacity: 66%;
   font-size: 0.75rem;

   padding-left: 0.25rem;
   padding-right: 0.25rem;
   border-radius: 0.25rem;
}

.primary-text {
   font-size: calc(0.75rem + 0.6vw);
}
.video-caption-overlay {
   position: absolute;
   left: 0.5rem;
   right: 0.5rem;
   bottom: 0.75rem;
   z-index: 2;
}
.video-col {
   flex-grow: 1;
   flex-shrink: 1;
   position: relative;
}