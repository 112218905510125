body {
   margin: 0;
   font-family: "Poppins", "Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
}

code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.body {
   font-family: Arial, sans-serif;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
   background-color: #f7f7f7;
   margin: 0;
 }
 
 .form-container {
   background-color: white;
   padding: 20px;
   border-radius: 10px;
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
   width: 100%;
   max-width: 400px;
   text-align: center;
 }
 
 h1 {
   color: #e62e84;
   font-size: 2rem;
   margin-bottom: 10px;
 }
 
 h2 {
   color: #333;
   font-size: 1.5rem;
   margin-bottom: 10px;
 }
 
 p {
   color: #555;
   margin-bottom: 20px;
 }
 
 input {
   width: 100%;
   padding: 10px;
   margin: 10px 0;
   border: 1px solid #ccc;
   border-radius: 5px;
   font-size: 0.75rem;
 }
 
 .button {
   width: 100%;
   padding: 12px;
   background-color: #e62e84;
   color: white;
   border: none;
   border-radius: 5px;
   font-size: 1rem;
   cursor: pointer;
 }
 
 .button:hover {
   background-color: #d12b70;
 }
 
 a {
   color: #e62e84;
   text-decoration: none;
 }
 
 a:hover {
   text-decoration: underline;
 }

 .logo {
   width: 120px; /* Adjust size */
   height: auto;
   margin-bottom: 10px;
 }
 .error-message{
   margin-top: 2px;
   color: red;
 }
 
