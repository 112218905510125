.bottom-right-fixed {
   position: fixed;
   bottom: 0.5rem;
   right: 0.75rem;
}

.bottom-right-position {
   position: absolute;
   bottom: 0.5rem;
   right: 1.75rem;
}

.flex-center {
   display: flex;
   justify-content: center;
   align-items: center;

   >.flex-center {
      flex-direction: column;
   }
}
.top-right-position {
   position: absolute;
   top: 0.5rem;
   right: 0.75rem;
}