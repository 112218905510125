$primary: #f04770;
$secondary: #1d0b81;
$success: #06d7a0;
$light: #F7F4FF;
$dark: #B2BBC3;
$info: #9b88fa;
@import "../node_modules/bootstrap/scss/bootstrap";

.btn-primary, .btn-success {
   --bs-btn-color: white;
   --bs-btn-disabled-color: white;
   --bs-btn-hover-color: white;
   --bs-btn-active-color: #f0f0f0;
}

.btn {
   --bs-btn-padding-y: 0.65rem;
   --bs-btn-padding-x: 1rem;
   --bs-btn-border-radius: 0.45rem;
   --bs-btn-font-weight: 500;
   --bs-btn-line-height: 1.3;
}

.btn-sm {
   --bs-btn-padding-y: 0.25rem;
   --bs-btn-badding-x: 0.5rem;
}

.btn-outline-secondary {
   --bs-btn-bg: #f7f4ff;
   --bs-btn-border-color: #dee2e6;
   --bs-btn-disabled-bg: #f7f4ff;
   --bs-btn-disabled-border-color: #dee2e6;
}