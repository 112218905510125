@import './coach.scss';
@import './general.scss';
@import './generator.scss';
@import './interview.scss';
@import './positioning.scss';

// Generic classes
.cursor-pointer {
   cursor: pointer !important;
}
.flex-basis-0 {
   flex-basis: 0;
}
.flex-basis-25 {
   flex-basis: 25%;
}

.flex-basis-75 {
   flex-basis: 75%;
}

.round-bottom {
   border-bottom-left-radius: 1.5rem !important;
   border-bottom-right-radius: 1.5rem !important;
}

.square-bottom {
   border-bottom-left-radius: 0 !important;
   border-bottom-right-radius: 0 !important;
}