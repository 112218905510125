@import './scss/index.scss';
// ========== Additional Custom Variables ==========
$gl-xs: "screen and (max-width: 36em)";

.App {
  display: flex;
  flex-direction: column;
  height: 100vh;

  .ai-button {
    background: linear-gradient(163deg, #ac38fa, #0552b5);
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.2s ease;
  
    &:hover {
      background: linear-gradient(163deg, #ac38fa, #b5057f);
    }
  }

  .icon {
    filter: invert(1);
  }
  

  .result-area {
    flex: 2;
    display: flex;
    flex-direction: column;
    position: relative;

    // p {
    //   padding-bottom: 5px;
    // }
  }
  
  p {
    //padding-bottom: 5px;
    // margin-bottom: 5px;
    font-size: 17px;
    font-weight: 700;
    //line-height: 1.2;
  }

  textarea {
    flex: 1;
    border: 1px solid #b7bbd1;
    border-radius: 6px;
    resize: none;
    // transition: border-color .2s;

    // &:hover {
    //   border: 1px solid var(--bs-secondary);
    // }
  }
}

.horizontal-button-list {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  height: 100%;
  
  svg {
    width: 24px;
    min-width: 24px;
  }
  .btn-sm {
    line-height: 17px;
  }
}

// Text cells earlier in full size table
td {
  vertical-align: middle;
}

.table-expand-area-item {
  // Override variables
  --bs-bg-opacity: 1;
  // Styling. Most vars inherited from table
  padding: 1rem;
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  background-color: #F7F4FF; //rgba(var(--bs-light-rgb), var(--bs-bg-opacity));
  display: flex;
  flex-direction: column;

  .text-section {
    margin-top: 1rem;
    white-space: pre-wrap;
  }
}

// ========== Resize table on mobile ==========
@media #{$gl-xs} {
  table {
    display: block;
    position: relative;

    * {
      position: relative;
    }

    >*,
    tr,
    td,
    th {
      display: block;
    }

    thead {
      display: none;
    }

    tbody tr {
      height: auto;
      //padding: 8px 0;

      td[data-col] {
        padding-left: 40% !important;
        //margin-bottom: 12px;

        // &:last-child {
        //   margin-bottom: 0;
        // }

        &:before {
          position: absolute;
          font-weight: 700;
          width: 35%;
          //top: 0;
          left: 8px;
          content: attr(data-col);
        }
      }
    }
  }
}